<template>
  <h2
    ref="templateRefHeading"
    v-element-visibility="(state) => onElementVisibilityHandler(state)"
  >
    <slot />
  </h2>
</template>

<script lang="ts" setup>
import { vElementVisibility } from "@vueuse/components";

defineSlots<{
  default: () => void;
}>();

const templateRefHeading = useTemplateRef("templateRefHeading");

const onElementVisibilityHandler = (state: boolean) => {
  if (!state || templateRefHeading.value === null || templateRefHeading.value?.id === null) {
    return;
  }

  history.replaceState(history.state, "", `#${templateRefHeading.value.id}`);
};
</script>
