<template>
  <BaseModalButton
    :modal="MODAL.FaqArticlesImage"
    @click="() => emits('click', imageSource)"
  >
    <BasePicture
      v-bind="{ ...(imageWidth && { style: { maxWidth: `${imageWidth}px` } }) }"
      :src="imageSource"
    />
  </BaseModalButton>
</template>

<script lang="ts" setup>
defineProps<{
  imageSource: string;
  imageWidth?: string;
}>();

const emits = defineEmits<{
  click: [imageSource: string];
}>();
</script>
