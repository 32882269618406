<template>
  <FaqArticlesBotDlyaKlientov v-if="route.params.slug === 'bot-dlya-klientov'" />

  <LazyFaqArticlesChatSKlientom v-if="route.params.slug === 'chat-s-klientom'" />

  <LazyFaqArticlesChtoTakoyeDonut v-if="route.params.slug === 'сhto-takoye-donut'" />

  <LazyFaqArticlesDobavlenieIRedaktirovanieTovara v-if="route.params.slug === 'dobavlenie-i-redaktirovanie-tovara'" />

  <LazyFaqArticlesDobavlenieIRedaktirovanieZakaza v-if="route.params.slug === 'dobavlenie-i-redaktirovanie-zakaza'" />

  <LazyFaqArticlesDobavlenieIRedaktirovanieZapisi v-if="route.params.slug === 'dobavlenie-i-redaktirovanie-zapisi'" />

  <LazyFaqArticlesDobavleniyeIRedaktirovaniyeKlienta
    v-if="route.params.slug === 'dobavleniye-i-redaktirovaniye-klienta'"
  />

  <LazyFaqArticlesDobavleniyeIRedaktirovaniyeSotrudnika
    v-if="route.params.slug === 'dobavleniye-i-redaktirovaniye-sotrudnika'"
  />

  <LazyFaqArticlesDobavleniyeIRedaktirovaniyeUslugi
    v-if="route.params.slug === 'dobavleniye-i-redaktirovaniye-uslugi'"
  />

  <LazyFaqArticlesDobavleniyeIRedaktirovaniyeZayavki
    v-if="route.params.slug === 'dobavleniye-i-redaktirovaniye-zayavki'"
  />

  <LazyFaqArticlesInformatsionnyyeStranitsy v-if="route.params.slug === 'informatsionnyye-stranitsy'" />

  <LazyFaqArticlesKategoriiTovarov v-if="route.params.slug === 'kategorii-tovarov'" />

  <LazyFaqArticlesKategoriiUslug v-if="route.params.slug === 'kategorii-uslug'" />

  <LazyFaqArticlesKonstruktorBota v-if="route.params.slug === 'konstruktor-bota'" />

  <LazyFaqArticlesKonstruktorSayta v-if="route.params.slug === 'konstruktor-sayta'" />

  <LazyFaqArticlesKontakty v-if="route.params.slug === 'kontakty'" />

  <LazyFaqArticlesKontaktyIDannyeFilialov v-if="route.params.slug === 'kontakty-i-dannye-filialov'" />

  <LazyFaqArticlesNastroykaMenyuCrm v-if="route.params.slug === 'nastroyka-menyu-crm'" />

  <LazyFaqArticlesNastroykiZapisey v-if="route.params.slug === 'nastroyki-zapisey'" />

  <LazyFaqArticlesObshchiyeNastroyki v-if="route.params.slug === 'obshchiye-nastroyki'" />

  <LazyFaqArticlesOnlaynUslugi v-if="route.params.slug === 'onlayn-uslugi'" />

  <LazyFaqArticlesPodklyuchenieKTelegram v-if="route.params.slug === 'podklyuchenie-k-telegram'" />

  <LazyFaqArticlesPolitikiKonfidencialnosti v-if="route.params.slug === 'politiki-konfidencialnosti'" />

  <LazyFaqArticlesRaspisaniyeRaboty v-if="route.params.slug === 'raspisaniye-raboty'" />

  <LazyFaqArticlesRegistratsiyaINachaloRaboty v-if="route.params.slug === 'registratsiya-i-nachalo-raboty'" />

  <LazyFaqArticlesSaytDlyaKlientov v-if="route.params.slug === 'sayt-dlya-klientov'" />

  <LazyFaqArticlesSpisokKlientovIIkhDannye v-if="route.params.slug === 'spisok-klientov-i-ikh-dannye'" />

  <LazyFaqArticlesSpisokSotrudnikovIIkhDannye v-if="route.params.slug === 'spisok-sotrudnikov-i-ikh-dannye'" />

  <LazyFaqArticlesSpisokTovarovIIkhDannye v-if="route.params.slug === 'spisok-tovarov-i-ikh-dannye'" />

  <LazyFaqArticlesSpisokUslugIIkhDannye v-if="route.params.slug === 'spisok-uslug-i-ikh-dannye'" />

  <LazyFaqArticlesSpisokZakazovIIkhDannye v-if="route.params.slug === 'spisok-zakazov-i-ikh-dannye'" />

  <LazyFaqArticlesSpisokZayavokIIkhDannye v-if="route.params.slug === 'spisok-zayavok-i-ikh-dannye'" />

  <LazyFaqArticlesSposobyDostavki v-if="route.params.slug === 'sposoby-dostavki'" />

  <LazyFaqArticlesTarify v-if="route.params.slug === 'tarify'" />

  <LazyFaqArticlesTsifrovyeTovary v-if="route.params.slug === 'tsifrovye-tovary'" />

  <LazyFaqArticlesUvedomleniya v-if="route.params.slug === 'uvedomleniya'" />

  <LazyFaqArticlesUvedomleniyaKlienta v-if="route.params.slug === 'uvedomleniya-klienta'" />

  <LazyFaqArticlesUvedomleniyaSotrudnika v-if="route.params.slug === 'uvedomleniya-sotrudnika'" />

  <LazyFaqArticlesYazykIRegion v-if="route.params.slug === 'yazyk-i-region'" />

  <LazyFaqArticlesZhurnalZapisejIIkhDannye v-if="route.params.slug === 'zhurnal-zapisej-i-ikh-dannye'" />

  <LazyFaqArticlesChtoTakoyeZayavkiNaUslugi v-if="route.params.slug === 'chto-takoye-zayavki-na-uslugi'" />

  <div v-else />
</template>

<script lang="ts" setup>
const route = useRoute();

definePageMeta({
  layout: "faq",
});
</script>
