<template>
  <aside class="aside">
    <div class="aside__block">
      <p class="typography__title1">В этой статье</p>

      <ul class="aside__list">
        <li
          v-for="(element, elementIndex) in menu"
          :key="elementIndex"
          class="aside__item"
        >
          <BaseLink
            class="typography__body2 aside__link"
            :class="{ active: activeElement === element.url }"
            :href="`#${element.url}`"
          >
            <span v-html="element.name" />
          </BaseLink>
        </li>
      </ul>
    </div>

    <div class="aside__buttons">
      <BaseButton2 class="typography__label1 aside__button">
        <BaseIcon2
          id="icon-like"
          class="aside__icon"
        />

        Было полезно
      </BaseButton2>

      <BaseButton2 class="typography__label1 aside__button">
        <BaseIcon2
          id="icon-like"
          class="aside__icon aside__icon--dislike"
        />

        Не помогло
      </BaseButton2>
    </div>
  </aside>
</template>

<script lang="ts" setup>
const { menu } = defineProps<{
  menu: {
    name: string;
    url: string;
  }[];
}>();

const activeElement = ref<null | string>(null);
const elements = ref<Element[]>([]);

const { resume } = useIntersectionObserver(
  elements,
  ([entry]) => {
    if (entry !== undefined && entry.isIntersecting && entry.target.id) {
      activeElement.value = entry.target.id;
    }
  },
  {
    immediate: false,
  },
);

onMounted(() => {
  menu.forEach((menuElement) => {
    const element = document.querySelector(`#${menuElement.url}`);

    if (element) {
      elements.value.push(element);
    }
  });

  resume();
});
</script>

<style scoped>
.aside {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
}

@media (--xl) {
  .aside {
    position: sticky;
    top: calc(var(--header-height) + 24px);
    flex-shrink: 0;
    width: 280px;
  }
}

.aside__block {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.aside__list {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.aside__link {
  position: relative;
  display: inline-flex;
  align-items: start;
  column-gap: 16px;
}

.aside__link.active {
  color: var(--blue-1);
}

.aside__link::before {
  content: "";
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin-top: 2px;
  border-radius: 50%;
  background-color: var(--grey-4);
}

.aside__link.active::before {
  background-color: var(--blue-1);
}

.aside__item:not(:last-child) .aside__link::after {
  content: "";
  position: absolute;
  top: 20px;
  left: 8px;
  width: 1px;
  height: calc(100% - 6px);
  background-color: var(--grey-4);
}

.aside__buttons {
  display: flex;
  column-gap: 12px;
}

.aside__button {
  display: flex;
  align-items: center;
  column-gap: 8px;
  padding: 4px 12px;
  color: var(--grey-2);
  border-radius: 8px;
  background-color: var(--white);
}

.aside__icon {
  width: 24px;
  height: 24px;
  color: var(--grey-3);
}

.aside__icon--dislike {
  scale: 1 -1;
}
</style>
