<template>
  <section class="page">
    <div class="page__header">
      <div class="page__title">
        <SharedBreadcrumbs :breadcrumbs />

        <FaqSearch />
      </div>

      <h1 class="typography__headline1">Бот для клиентов</h1>
    </div>

    <div class="page__content">
      <FaqArticlesAside :menu/>

      <FaqArticlesContent>
        <div class="base-content-block">
          <FaqArticlesBlockHeading
            id="kak-naiti"
            class="typography__headline2"
          >
            Как найти
          </FaqArticlesBlockHeading>

          <div class="block-small">
            <p>Ссылку на&nbsp;вашего бота, поключенного к&nbsp;Telegram, можно найти в&nbsp;разделе &laquo;Бот&raquo; или в&nbsp;самом Telegram в&nbsp;BotFather.</p>

            <p>Пока интеграция доступна только с&nbsp;Telegram. В&nbsp;разработке находится подключение к&nbsp;Instagram, Snapchat и&nbsp;Facebook Messenger.</p>
          </div>

          <FaqArticlesImageModalButton
            image-source="/images/faq/bot-dlya-klientov-1.jpg"
            @click="(imageSource) => (activeImageSource = imageSource)"
          />
        </div>

        <div class="base-content-block">
          <FaqArticlesBlockHeading
            id="rabota-s-botom"
            class="typography__headline2"
          >
            Работа с ботом
          </FaqArticlesBlockHeading>

          <div class="block-small">
            <p>Для начала работы с&nbsp;ботом нужно открыть ссылку на&nbsp;него в&nbsp;Telegram.</p>

            <p>В&nbsp;открывшемся диалоге нажмите внизу &laquo;Старт&raquo;.</p>
          </div>

          <FaqArticlesImageModalButton
            image-source="/images/faq/bot-dlya-klientov-2.jpg"
            @click="(imageSource) => (activeImageSource = imageSource)"
          />

<div class="block-small">
  <p>В&nbsp;чате можно позвать менеджера и&nbsp;задать интересующие вопросы. Также здесь будут приходить уведомления о&nbsp;предстоящих записях, изменениях статусов заказов и&nbsp;другие важные сообщения.</p>

  <p>По&nbsp;кнопке &laquo;Открыть сайт&raquo; можно сразу перейти к&nbsp;работе с&nbsp;сайтом и&nbsp;записаться на&nbsp;услугу или купить товары.</p>

  <p>По&nbsp;кнопке меню, внизу справа, можно открыть панель быстрых действий.</p>
</div>

          <FaqArticlesImageModalButton
            image-source="/images/faq/bot-dlya-klientov-3.jpg"
            @click="(imageSource) => (activeImageSource = imageSource)"
          />

         <p>Из&nbsp;панели быстрых действий можно перейти к&nbsp;заказам и&nbsp;записям или позвать в&nbsp;чат менеджера.</p>

          <FaqArticlesImageModalButton
            image-source="/images/faq/bot-dlya-klientov-4.jpg"
            @click="(imageSource) => (activeImageSource = imageSource)"
          />

          <p>Как работать с ботами разобрались. Самое время перейти к <BaseLink
            class="ui-link-secondary"
            to="/faq/categories/sayt"
          >изучению и настройке сайта</BaseLink>.</p>
        </div>
      </FaqArticlesContent>
    </div>

    <div class="page__related">
      <h2 class="typography__headline2">Другие статьи по&nbsp;этой теме</h2>

      <FaqArticlesList :articles="relatedArticles" />
    </div>
  </section>

  <LazyFaqArticlesImageModal
    v-if="isModalOpen(MODAL.FaqArticlesImage) && activeImageSource"
    :src="activeImageSource"
    @close="() => (activeImageSource = null)"
  />
</template>

<script lang="ts" setup>
const { isModalOpen } = useModalStore();
const activeImageSource = ref<null | string>(null);

const breadcrumbs = [
  {
    name: "База знаний",
    url: "/faq",
  },
  {
    name: "Бот и&nbsp;интеграции",
    url: "/faq/categories/bot-i-integratsii",
  },
  {
    name: "Бот для клиентов",
  },
];

const menu = [
  {
    name: "Как&nbsp;найти",
    url: "kak-naiti",
  },
  {
    name: "Работа&nbsp;с&nbsp;ботом",
    url: "rabota-s-botom",
  },
];

const relatedArticles = [
  {
    name: "Конструктор бота",
    slug: "konstruktor-bota",
  },
  {
    name: "Уведомления клиента",
    slug: "uvedomleniya-klienta",
  },
  {
    name: "Подключение к&nbsp;Telegram",
    slug: "podklyuchenie-k-telegram",
  },
];

useSeoMeta({
  title: "Бот для клиентов",
});
</script>

<style scoped>
.page {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
}

.page__header {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
}

.page__title {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.page__content {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

@media (--xl) {
  .page__content {
    flex-direction: row-reverse;
    justify-content: start;
    align-items: start;
  }
}

.page__related {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}
</style>
